// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".home-slider")
};

sliders.home.slick({
  dots: false,
  autoplay: false
});
