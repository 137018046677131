/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import { getScreenHeight } from "./helper.js";
import CoreAnimation from "./animations/CoreAnimation";

export const toggleMenu = () => {
  let button = document.getElementById("toggle"),
    container = document.getElementById("site-navigation"),
    html = document.querySelector("html");

  if (button && container) {
    button.addEventListener("click", () => {
      CoreAnimation.slideToggle(
        document.querySelector("#site-navigation > div > ul")
      );

      if (button.classList.contains("open")) {
        button.classList.remove("open");
        html.style.overflowY = "auto";
        return;
      }

      button.classList.add("open");

      if (getScreenHeight() <= 1260) {
        html.style.overflowY = "hidden";
      } else {
        html.style.overflowY = "auto";
      }
    });
  }
};

export const closeMenu = () => {
  let links = document.querySelectorAll(".main-navigation a"),
    nav = document.getElementById("site-navigation"),
    toggle = document.getElementById("toggle"),
    html = document.querySelector("html");

  links.forEach(e => {
    e.addEventListener("click", () => {
      nav.classList.remove("open");
      toggle.classList.remove("open");
      html.style.overflowY = "auto";
      CoreAnimation.slideUp(
        document.querySelector("#site-navigation > div > ul")
      );
    });
  });
};

export const addSticky = () => {
  let toggle = document.getElementById("toggle"),
    header = document.getElementById("masthead");

  toggle.addEventListener("click", () => {
    header.classList.add("sticky");
  });
};
